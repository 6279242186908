.kubra-chat-window {
    background: $color-white;
    bottom: 0;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 0 16px 0 $color-alpha-black-1;
    box-sizing: border-box;
    color: $color-white;
    font-family: $fontRoboto;
    font-size: 16px;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    height: 376px;
    margin: 0;
    position: fixed;
    padding: 0;
    right: 5%;
    width: 320px;
    z-index: $zIndex;
    -webkit-font-smoothing: antialiased;

    &.short {
        height: $chat-header-height;
    }

    .adaText {
        display: none;
    }

    .kubra-chat-header {
        background: $base-color;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid $color-white;
        box-sizing: border-box;
        font-family: $fontRoboto;
        font-size: 14px;
        font-weight: 500;
        height: $chat-header-height;
        line-height: 1.35rem;
        overflow: hidden;
        padding: 15px 2px 15px 16px;
        text-transform: uppercase;
        user-select: none;
        &.clickable {
            cursor: pointer;
        }
        button {
            background: none;
            border: 0;
            color: inherit;
            cursor: pointer;
            margin: 0 12px 4px;
            outline: none;
            padding: 0;
            span {
                &::before {
                    font-size: 14px;
                }
            }
        }
    }

    .kubra-chat-body {
        background: $color-white;
    }

    .kubra-chat-conversation {
        box-sizing: border-box;
        height: 272px;
        overflow: auto;
        overflow-y: scroll;
        padding: 16px;
        position: relative;
        width: 100%;

        .kubra-chat-private-notification {
            background: $color-white;
            color: $color-gray-dark;
            font-family: $fontRoboto;
            font-size: 18px;
            font-weight: 500;
            height: 225px;
            left: 0;
            line-height: 21px;
            padding-top: 40px;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;
            z-index: 100;

            .kubra-error {
                background: $color-gray;
                color: #fff;
            }
            .subtext {
                color: $color-gray;
                font-size: 14px;
                font-weight: 300;
                line-height: 16px;
                margin: 10px auto;
                text-align: center;
                width: 220px;
            }
            a {
                border-radius: 2px;
                margin: 15px 10px 0;
                width: 160px;
            }
            .kubra-btn {
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                outline: none;
                text-decoration: none;
                text-transform: uppercase;
            }
            .kubra-yes-btn,
            .kubra-chat-btn {
                background: $base-color;
                border: $base-color;
                border-radius: 2px;
                color: $color-white;
                height: 32px;
                margin-top: 16px;
                width: 159px;
                &:hover {
                    border: 2px solid $base-color;
                    background: none;
                    color: $base-color;
                }
            }
            .kubra-no-btn,
            .kubra-cancel-btn {
                background: none;
                border: none;
                color: $color-gray-dark;
                margin-top: 8px;
                &:hover {
                    text-decoration: underline;
                }
            }

            .nameField {
                width: 220px;
                text-indent: 1.6rem;
                font-size: 1rem;
                border: 1px solid #CBD0D4;
                background: #F6F6F6;
                color: #242424;
                max-width: 60.8rem;
                height: 2.8rem;
                margin-bottom: 20px;
                &:focus {
                    background: #fff;
                    outline: none;
                }
            }
        }
    }
    .kubra-input-area {
        border: 1px solid $color-gray-light;
        height: 56px;
        padding: 5%;
        position: relative;
        #chatFieldPotLabel {
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            width: 0;
            z-index: -1;
        }
        label {
            span {
                display: none;
            }
        }
        #chatFieldPot {
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            width: 0;
            z-index: -1;
        }
        textarea {
            border: none;
            box-shadow: none;
            color: $color-gray-dark;
            font-family: $fontRoboto;
            font-size: 14px;
            line-height: 16px;
            min-height: 20px;
            overflow:hidden;
            outline: none;
            padding: 0;
            resize: none;
            width: 80%;
        }

        button {
            background: $base-color;
            border-radius: 20px;
            border: 0;
            color: $color-white;
            cursor: pointer;
            height: 40px;
            outline: none;
            position: absolute;
            right: 8px;
            top: 8px;
            width: 40px;
        }
    }
    .chat-bubble {
        border-radius: 8px;
        border-bottom-left-radius: 0;
        box-shadow: 2px 2px 3px 0 $color-alpha-black-2;
        color: $color-white;
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 16px 0;
        padding: 16px;
        width: 242px;
        word-break: break-word;
    }
    .iq {
        background-color: $color-gray-light-2;
        color: $color-gray-dark;
        float: left;
    }
    .user {
        background-color: $base-color;
        float: right;
    }
    .loader-holder {
        float: left;
        position: relative;
        width: 100%;
    }
    .chat-holder {
        float: left;
        width: 100%;
    }

    @keyframes blink {
        0% {
            opacity: .2;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: .2;
        }
    }
    
    .kubra-chat-loader {
        background-color: $color-white-off;
        border-radius: 8px;
        box-shadow: 2px 2px 3px 0 $color-alpha-black-2;
        height: 23px;
        padding-left: 4px;
        width: 45px;
        div {
            animation-duration: 1.4s;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
            animation-name: blink;
            background-color: $color-gray;
            border-radius: 5px;
            float: left;
            height: 6px;
            margin-left: 5px;
            margin-top: 9px;
            width: 6px;
            &:nth-child(2) {
                animation-delay: .2s;
            }
            &:nth-child(3) {
                animation-delay: .4s;
            }
        }
    }
}
