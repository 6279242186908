// Fonts
$roboto-font-path: '../assets/' !default;

@font-face {
  font-family: "Roboto";
  src: local(Roboto Regular),
  url("#{$roboto-font-path}Roboto-Regular.woff2") format("woff2"),
  url("#{$roboto-font-path}Roboto-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: local(Roboto Bold),
  url("#{$roboto-font-path}Roboto-Bold.woff2") format("woff2"),
  url("#{$roboto-font-path}Roboto-Bold.woff") format("woff");
  font-weight: 700;
}
