
.kubra-chat-cta {
    background: $base-color;
    border-radius: 0 0 8px 8px;
    box-shadow: -4px 2px 7px 1px rgba(36,36,36,0.5);
    box-sizing: border-box;
    color: $color-white;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    font-family: $fontRoboto;
    font-size: 15px;
    font-weight: 500;
    justify-content: center;
    line-height: 24px;
    margin: auto;
    min-width: 40px;
    padding: 20px 5px;
    position: fixed;
    text-transform: capitalize;
    user-select: none;
    word-wrap: normal;
    z-index: $zIndex;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    
    &.left {
        left: 0;
        top: 50%;
        transform: rotate(-90deg) translate(-50%, 50%);
        transform-origin: 0 50%;
    }
    &.right {
        right: 0;
        top: 50%;
        transform: rotate(90deg) translate(50%, 50%);
        transform-origin: 100% 50%;
    }
    &.bottom {
        border-radius: 8px 8px 0 0;
        bottom: 0;
        right: 10%;
    }
    p {
        color: inherit;
        margin: 0 10px;
        text-align: center;
    }
}