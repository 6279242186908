$base-color: #4B5B68;
$color-blue-light: #82C9E2;
$color-white: #fff;
$color-white-off: #F6F6F6;
$color-gray: #4A4A4A;
$color-gray-light: #CAC8C8;
$color-gray-light-2: #EAECEE;
$color-gray-dark: #242424;
$color-alpha-black-1: rgba(0,0,0,0.5);
$color-alpha-black-2: rgba(0,0,0,0.15);
$fontRoboto: Roboto, HelveticaNeue, Helvetica Neue,Helvetica,Arial,sans-serif;
$fontIconic: 'Iconic';
$zIndex: 10000;
$chat-header-height: 48px;